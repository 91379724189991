import { format, getYear, isMatch, parse } from 'date-fns'

export default function formatDate(dateString: string) {
  if (dateString === '' || dateString.toLowerCase() === 'null' || !dateString) {
    return '-'
  }

  let parsedDate

  try {
    if (isMatch(dateString, 'yyyy-MM-dd')) {
      parsedDate = parse(dateString, 'yyyy-MM-dd', new Date())
    } else if (isMatch(dateString, 'yyyy-MM-dd HH:mm:ss.SSS xx')) {
      parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss.SSS xx', new Date())
    } else {
      parsedDate = new Date(dateString)
    }
  } catch (error) {
    console.log('[formatDate] failed to parse date', dateString)
    console.error(error)
    return '-'
  }

  if (!parsedDate) {
    return '-'
  }

  if (getYear(parsedDate) === 9999) {
    return 'never'
  }

  return format(parsedDate, 'MMM dd, yyyy')
}
